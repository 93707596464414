@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: archiaregular;
    font-weight: 400;
    src: url(../fonts/archia/archia-regular-webfont.woff) format("woff");
  }
}

.link {
  @apply text-blue-500 border-b border-blue-300 p-2 border-dotted;
}

.link:hover {
  @apply text-blue-600 border-b-2 border-blue-400 p-2 border-dotted;
}

.btn {
  @apply px-2 py-3 border rounded;
}

.btn-success {
  @apply bg-green-500 text-white border-green-300;
}

.btn-normal {
  @apply bg-gray-500 text-white border-gray-300;
}

.btn-danger {
  @apply bg-red-500 text-white border-red-300;
}

div.release-notes h1 {
  @apply text-lg font-semibold font-mono text-left;
}

div.release-notes h2 {
  @apply text-lg font-semibold font-mono text-left;
}

div.release-notes h3 {
  @apply text-lg font-semibold font-mono text-left;
}

div.release-notes p {
  @apply text-sm font-mono text-left mb-2;
}

div.release-notes ul {
  @apply mt-4
}

div.release-notes ul li {
  @apply list-item list-inside list-disc text-left px-2 py-1 font-mono text-sm;
}




div.markdown h1 {
  @apply text-xl font-semibold font-mono text-left;
}

div.markdown h2 {
  @apply text-lg font-semibold font-mono text-left;
}

div.markdown h3 {
  @apply font-semibold font-mono text-left;
}

div.markdown p {
  @apply text-sm font-mono text-left mb-2;
}


div.markdown ul {
  @apply pt-2
}
div.markdown ul li {
  @apply list-item list-disc font-mono text-left px-2 py-1 font-mono;
}
